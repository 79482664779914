/* eslint-disable */
const $ = require('jquery');

import Http from "./http";
var $httpVue = null
function init(vue){
    $httpVue = vue
    var loginUser = localStorage.getItem('app_user_id');
    if(loginUser==null||loginUser=='null' || loginUser==undefined || loginUser=='undefined' || loginUser==''){
        vue.$router.push({
            path:'/Login'
        });
    }
    Http.init(vue);
}
function goTo(){
    $httpVue.$router.push({
        path:'/alarm'
    })
}
document.addEventListener("jpush.openNotification", function(event){
    //打开通知
    try {
          //获取极光推送附带的参数
          let platform = window.localStorage.getItem('platform');
          if (platform== "Android") {
                // alert(JSON.stringify(event.alert));
                goTo()
          } else {
                // alert(JSON.stringify(event.aps.alert));
                goTo()
          }
          //相应的动作
    } catch (exception) {
          alert("JPushPlugin:onOpenNotification" + JSON.stringify(exception));
    }
}, false);
document.addEventListener("jpush.receiveNotification", function(){
//收到通知  
    try {
          // let platform = window.localStorage.getItem('platform');
          // alert(platform);
          // if (platform== "Android") {
          //       alert(JSON.stringify(event.alert));
          // } else {
          //       alert(JSON.stringify(event.aps.alert));
          // }
          //相应的动作
    } catch (exception) {
          alert("JPushPlugin:onReceiveNotification" + JSON.stringify(exception));
    }
}, false);

// 查询数组中是否存在
const isHasValue = (value,list)=>{
    const hasList = list.find(items=>items.value == value);
    if(!hasList || list.length === 0){
        return 0;
    }else{
        return 1;
    }
}
// 树形转list
const treeToList = (tree,parentkey)=>{
    
    let arrs = [];
    let result = [];
    arrs = arrs.concat(tree);
    while (arrs.length) {
        let first = arrs.shift(); // 弹出第一个元素
        if (first[parentkey]) {
            //如果有children
            arrs = arrs.concat(first[parentkey]);
        }
        result.push(first);
    }
    return result;
}
// 获取当前页面的权限
const getPagePermission = (index)=>{
    const obj = {
        selectflag:0,
        insertflag:0,
        deleteflag:0,
        exportflag:0,
        updateflag:0,
        uploadflag:0,
        table:""
    }
    var list = treeToList(JSON.parse(localStorage.getItem('app_permission')),'subs');
    list.forEach(element=>{
        if(element.index == index){
            obj.selectflag = element.selectflag;
            obj.insertflag = element.insertflag;
            obj.deleteflag = element.deleteflag;
            obj.exportflag = element.exportflag;
            obj.updateflag = element.updateflag;
            obj.uploadflag = element.uploadflag;
            obj.table = element.table;
        }
    });
    return obj;
}

export default { init ,goTo,isHasValue,getPagePermission}