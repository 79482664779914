/* eslint-disable */ 
import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";
import { createApp } from "vue";
import Vant from 'vant';
import 'vant/lib/index.css';
import App from "./App.vue";
import 'lib-flexible';
import 'amfe-flexible';
import "./assets/css/style.less";
import './assets/css/modules.less';
import router from "./router";
import store from "./store";
import http from "./api/http";
import global from "./api/global";
import './assets/icons-lrt/iconfont.css';
import Jpush from "./api/jpush";
import { ImagePreview } from 'vant';

var myApp = createApp(App)
.use(store)
.use(http)
.use(router)
.use(Vant)
.use(Jpush)
.use(ImagePreview)
.use(ElementPlus, { size: "small", zIndex: 3000 })
.mount("#app");
global.init(myApp);
      
var initJPush = function(){
      if ('JPush' in window) {
            try {
                  window.JPush.init();
                  window.JPush.setDebugMode(true);
                  window.setTimeout(() => {
                        window.JPush.getRegistrationID(() => {});
                  }, 1000);
                  if (device.platform != "Android") {
                        window.JPush.setApplicationIconBadgeNumber(0);
                  }
            } catch (exception) {}
      } else {}
}
var onDeviceReady = function(){
      window.localStorage.setItem('platform',device.platform);
      document.addEventListener("jpush.receiveRegistrationId", function () {
            window.JPush.setDebugMode(true)
      }, false);
      initJPush();
      // alert(JSON.stringify(navigator.camera));
}

document.addEventListener("deviceready", onDeviceReady, false);