/* eslint-disable */ 
import { createStore } from "vuex";

export default createStore({
  state: {
    apiConfig:{
       
    }
  },
  mutations: {
    setApi(state,data){
      state.apiConfig=data;
    }
  },
  modules: {
    
  }
});
