import dayjs from "dayjs";

/* eslint-disable */
const axios = require("axios"); ////请求插件
const qs = require("qs"); ///参数解析插件
const { ElMessage ,ElLoading} = require("element-plus");
const global = require("./global");
const { Toast,Notify } = require("vant"); 

var $vueHttp = null
function init(vue){
    $vueHttp = vue;
    
}
// UMengPush.onSubscriptNotification(function(data){
//     //点击通知就会触发这里的代码
//     alert(JSON.stringify(data));
// });
// 初始加载
/////默认超时时间
axios.defaults.timeout = 30 * 60 * 1000;
///////request 拦截
axios.interceptors.request.use(config => {
    return config;
}, error => {
    return Promise.reject(error);
});
// 获取config参数
function config(){//全局函数1
    var config = {
        api:'https://ehs.haaenclean.com/ehsApi/client/api/app',
        uploadApi:"https://ehs.haaenclean.com/ehsApi/client/api/o_upload_image",
        base64Upload:'https://ehs.haaenclean.com/ehsApi/client/api/base64_o_upload_image',
        imgUrl:"https://ehs.haaenclean.com/ehsApi",
        baseUrl:'https://ehs.haaenclean.com/ehsApi'
    }
    return config;
};
function getImgUrl(){
    var config = {
        api:'https://ehs.haaenclean.com/ehsApi/client/api/app',
        uploadApi:"https://ehs.haaenclean.com/ehsApi/client/api/o_upload_image",
        imgUrl:"https://ehs.haaenclean.com/ehsApi",
    }
    return config.imgUrl
}
var $loading = null;
let loading = {
    show:function() {  //可选值为true,string="当前提示的文本"
        $loading = ElLoading.service({
            lock: true,
            text: '拼命加载中，请耐心等待！',
            spinner: 'el-icon-loading',
            background: 'rgba(58, 61, 63, 0.32)'
        });
    },
    close() {
        // let loadingInstance = ElLoading.service({
        //     lock: true,
        //     text: '拼命加载中，请耐心等待！',
        //     spinner: 'el-icon-loading',
        //     background: 'rgba(58, 61, 63, 0.32)'
        // });
        $vueHttp.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
            if(!$loading) return false;
            $loading.close();
        });
        // $loading.close();
    }
}
// response 拦截
axios.interceptors.response.use(
    response => {
        Toast.clear()
        let responseData = response.data;
        return responseData;
    },
    error => {
    if (error.response) {
        Toast.clear()
        switch (error.response.status) {
            case 401:
                Notify({ type: 'danger', message: '网络异常或服务器故障！',duration: 2000, });
                return Promise.reject(error);
            case 500:
                Notify({ type: 'danger', message: '网络异常或服务器故障！',duration: 2000, });
                return Promise.reject(error);
            case 404:
                Notify({ type: 'danger', message: '未查找到对应的数据！',duration: 2000, });
                return Promise.reject(error);
            default:
                Notify({ type: 'danger', message: '网络异常或服务器故障！',duration: 2000, });
                ElMessage.error('网络异常或服务器故障！');
            return;
        }
    }else{
        if(error.code == "ECONNABORTED"){
            Notify({ type: 'danger', message: '请求超时！',duration: 2000, });
            return Promise.reject(error)
        }
        Notify({ type: 'danger', message: '请求超时！',duration: 2000, });
        return Promise.reject(error)
    }
    return Promise.reject(error) //返回接口返回的错误信息
});
// 参数解析
function handleParams (data) {
    return qs.stringify(data,{
        allowDots:true
    });
}
// 提交的时候 移除columns 
function removeCoum(query){
    var queryObj = {}
    for(var key in query){
        if(key!="columns"){
            queryObj[key] = query[key]
        }
    }
    return queryObj
}
//  登出请求
function loginOut(){

}


// get 请求
function get(param,isResponese,isLoading,hasMap){
    if(param.method!="login" && param.method!="register"){
        param.token = localStorage.getItem("app_token")!=
        "undefined"&&localStorage.getItem("app_token")!=null?
        localStorage.getItem("app_token"):"";
        // "f70bd8391494ae92e0c8ba788010c0387b3bb452aaa2091cfa1624cd45496d49"
        // "aae7b85b429ebd0fefb7b810ec5751d071188221e66249280054d10c0f35e7b5"
        // "aa7333de10b929347a6277f970b731a404c412224bf98e92836ae5847fedbf08";
    }
    if(isLoading){
        Toast.loading({duration: 0,forbidClick: true,message: '',loadingType: 'spinner'});
    }
    var apiConfig = config()
    return new Promise((resolve, reject) =>{
        axios.get(
            apiConfig.api, 
            {
                params: param,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    "Accept":"text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9"
                },
            }).then(response=>{
            if(!response)return false
            switch(response.resultState){
                case "0":
                    Notify({ type: 'danger', message: response.resultMessage,duration: 2000, });
                    // ElMessage.error(response.resultMessage);
                    reject(response.resultData)
                break;
                case "1":
                    var result = response.resultData; 
                    localStorage.setItem("app_token", response.token);
                    if(param.method == "login"){
                        localStorage.setItem("app_version",apiConfig.Version);
                    }
                    if(isResponese){
                        resolve(response);
                    }else{
                        if(hasMap){
                            resolve(result.map);
                        }else{
                            resolve(result);
                        }
                        
                    }
                break;
                case "2":

                break;
                default:
                    loginOut(response)
                break;
            };
        },err =>{
            reject(err)
        })
        .catch(err =>{
            reject(err)
        });
    });
};
// post 请求
function post(param,isResponese,isLoading){
    if(isLoading){
        Toast.loading({duration: 0,forbidClick: true,message: '',loadingType: 'spinner'});
    }
    if(param.method!="login"){
        param.token = localStorage.getItem("app_token")!="undefined"?localStorage.getItem("app_token"):"";
    };
    var params = removeCoum(param);
    var apiConfig = config();
    return new Promise((resolve, reject) => {
        axios.post(
            apiConfig.api,
            handleParams(params),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    "Accept":"text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9"
                },
            }
        )
        .then(response =>{
            if(!response) return false;
            switch (response.resultState){
                case "0":
                    Notify({ type: 'danger', message: response.resultMessage,duration: 2000, });
                    reject(response)
                break;
                case "1":
                    var result = response.resultData;
                    if(isResponese == true){
                        resolve(response); 
                    }else{
                        resolve(result); 
                    }
                    
                break;
                case "-1":
                    Notify({ type: 'danger', message: 'token失效',duration: 2000, });
                    $vueHttp.$router.push({
                        path:'/Login'
                    });
                break;
                default:
                    loginOut(response)
                break;
            };
        },err => {
            reject(err)
        })
        .catch(err =>{
            reject(err)
        })
    });
};
// base64上传
// 上传
function base64Upload(params){
    var apiConfig = config();
    return new Promise((resolve, reject) =>{
        axios.post(apiConfig.base64Upload,handleParams(params))
        .then(response=>{
            if(!response)return false
            resolve(response.relativePath);
        },err =>{
            reject(err)
        })
        .catch(err =>{
            reject(err)
        });
    });
}
// 图片上传方法
function fileuoload(param){
    var apiConfig = config()
    return new Promise((resolve, reject) =>{
        // handleParams(param)
        axios.post(apiConfig.uploadApi,param)
        .then(response=>{
            if(!response)return false
            if(response.resultState == 0){
                Notify({message:response.resultMessage,type:'danger'});
                reject(err)
            }else{
                resolve(response.map.relativePath);
            }
            
        },err =>{
            reject(err)
        })
        .catch(err =>{
            reject(err)
        });
    });
};
// 微信接口请求
function wechartGet(params){
    var apiConfig = config()
    return new Promise((resolve, reject)=>{
        axios.get(apiConfig.api, {params: params})
        .then(response=>{
            if(!response)return false
            switch(response.resultState){
                case '0':
                    // ElMessage({type:'error',resultMessage:response.resultMessage,showClose:true})

                    Notify({ type: 'danger', message: response.resultMessage,duration: 2000, });
                break;
                case "1":
                    var result = response.resultData;
                    resolve(result); 
                break;
            }
            resolve(response)
        })
        .catch(err =>{
            reject(err)
        });
    })
};



// function login(){
//     var userInfo = JSON.parse(localStorage.getItem('userInfo'));
//     get('register',{
//         loginName:userInfo.mobile,
//         name:userInfo.name,
//         mobile:userInfo.mobile,
//         image:userInfo.qr_code,
//         openid:userInfo.userid
//     })
//     .then(res=>{
//         localStorage.setItem('loginUser',JSON.stringify(res.map))
//         $vueHttp.$router.push({
//             name:'app_dashboard'
//         });
//     }).catch(()=>{})
// }

//DES加密 Pkcs7填充方式
function encryptByDES(message){
    const keyHex = CryptoJS.enc.Utf8.parse('abcdefghijklmn');
    const encrypted = CryptoJS.DES.encrypt(message, keyHex, {
     mode: CryptoJS.mode.ECB,
     padding: CryptoJS.pad.Pkcs7
     });
    return encrypted.toString();
  }
  //DES解密
  function decryptByDES(ciphertext){
    const keyHex = CryptoJS.enc.Utf8.parse('abcdefghijklmn');
    // direct decrypt ciphertext
    const decrypted = CryptoJS.DES.decrypt({
       ciphertext: CryptoJS.enc.Base64.parse(ciphertext)
     }, keyHex, {
       mode: CryptoJS.mode.ECB,
       padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

// 风向转化
function toDirStr(num) {
    var num = parseInt(num)
    var N = '北';
    var E = '东';
    var S = '南';
    var W = '西';
    var dir = '';

    if (num == 0 || num == 360) {
        dir = "北风";
    } else if (num < 90 && num > 0) {
        dir = "西北风";
        // if(num<45){
        //     dir=N+'偏'+E;
        // }else if(num==45){
        //     dir=E+N;
        // }else if(num>45){
        //     dir=E+'偏'+N;
        // }
    } else if (num == 90) {
        dir = '西风';//正'+E;
    } else if (num < 180 && num > 90) {
        dir = '西南风';
        // if(num<135){
        //     dir=E+'偏'+S;
        // }else if(num==135){
        //     dir=E+S;
        // }else if(num>135){
        //     dir=S+'偏'+E;
        // }
    } else if (num == 180) {
        dir = '南风';//+S;
    } else if (num < 270 && num > 180) {
        dir = '东南风';
        // if(num<225){
        //     dir=S+'偏'+W;
        // }else if(num==225){
        //     dir=W+S;
        // }else if(num>225){
        //     dir=W+'偏'+S;
        // }
    } else if (num == 270) {
        dir = '东风';//+W;
    } else if (num < 360 && num > 270) {
        dir = '东北风';
        // if(num<315){
        //     dir=W+'偏'+N;
        // }else if(num==315){
        //     dir=W+N;
        // }else if(num>315){
        //     dir=S+'偏'+W;
        // }
    }

    return dir;
}

function base64ToBlob(dataurl,filename) {
    let arr = dataurl.split(',')
    let mime = arr[0].match(/:(.*?);/)[1]
    let suffix = mime.split('/')[1]
    let bstr = atob(arr[1])
    let n = bstr.length
    let u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], `${filename}`, {
        type: mime
    })
}
// 数组去重
const unique = (arr)=>{
    // 如果新数组的当前元素的索引值 == 该元素在原始数组中的第一个索引，则返回当前元素
    return arr.filter(function(item,index){
        return arr.indexOf(item,0) === index;
    });
}

function resetSetItem(key, newVal) {
    if (key === 'pageTitle') {
        // 创建一个StorageEvent事件
        var newStorageEvent = document.createEvent('StorageEvent');
        const storage = {
            setItem: function (k, val) {
                sessionStorage.setItem(k, val);
  
                // 初始化创建的事件
                newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
  
                // 派发对象
                window.dispatchEvent(newStorageEvent)
            }
        }
        return storage.setItem(key, newVal);
    }
    else if (key === 'watchStorage') {
        // 创建一个StorageEvent事件
        var newStorageEvent = document.createEvent('StorageEvent');
        const storage = {
            setItem: function (k, val) {
                sessionStorage.setItem(k, val);
  
                // 初始化创建的事件
                newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
  
                // 派发对象
                window.dispatchEvent(newStorageEvent)
            }
        }
        return storage.setItem(key, newVal);
    }
}
// 时间转化
function momentDate(date,type){
  return dayjs(date).format(type)
}

const dataURLtoFile = (data)=>{ //将base64转换为文件
    let binary = atob(data.split(',')[1]);
    let mime = data.split(',')[0].match(/:(.*?);/)[1];
    let array = [];
    for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
    }
    let fileData = new Blob([new Uint8Array(array)], {
        type: mime,
        });

    let file = new File([fileData], `${new Date().getTime()}.png`, { type: mime });

　　　　　　return file;
}
export default { init ,loading,config,getImgUrl,get,post,fileuoload,wechartGet,encryptByDES,decryptByDES,toDirStr,base64ToBlob,resetSetItem,base64Upload,unique,momentDate,dataURLtoFile}




// exports.install = function (Vue) {
//     Vue.config.globalProperties.init = init;
//     // 函数挂载
//     Vue.config.globalProperties.config = config
//     Vue.config.globalProperties.getImgUrl = getImgUrl
    
//     // get请求
//     Vue.config.globalProperties.get = get
//     // post 请求
//     Vue.config.globalProperties.post = post
//     // 图片上传
//     Vue.config.globalProperties.fileuoload = fileuoload
//     // 微信请求
//     Vue.config.globalProperties.wechartGet = wechartGet
//     // 登录
//     // Vue.config.globalProperties.login = login;
//     // 加密
//     Vue.config.globalProperties.encryptByDES = encryptByDES;
//     // 解密
//     Vue.config.globalProperties.decryptByDES = decryptByDES;
//     //方向解析
//     Vue.config.globalProperties.toDirStr = toDirStr
//     // base64转图片
//     Vue.config.globalProperties.base64ToBlob = base64ToBlob
//     // 
//     Vue.config.globalProperties.resetSetItem = resetSetItem

//  };