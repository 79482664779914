/* eslint-disable */ 

import { createRouter,createWebHashHistory } from "vue-router";
import Login from "../views/Login.vue";
const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  // 主结构
  {
    path:"/page",
    name: "Page",
    component: ()=>import("../views/Master/page/page.vue"),
    children:[
      {
        path:"/index",
        name: "index",
        component: ()=>import("../views/Master/Index/Index.vue"),
        meta:{
          type:"",
          title:"首页",
          isBack:false,
          isRightAction:false,
          isDetail:false,
          className:'IndexBox',
          activeIndex:'index'
        },
      },
      {
        path:"/agency",
        name: "agency",
        component: ()=>import("../views/Master/Agency/Agency.vue"),
        meta:{
           type:"",
           title:"待办",
           isBack:false,
           isRightAction:false,
           isDetail:false,
           activeIndex:'agency'
        }
      },
      {
        path:"/personalCenter",
        name: "personalCenter",
        component: ()=>import("../views/Master/personalCenter/personalCenter.vue"),
        meta:{
           type:"",
           title:"我的",
           isBack:false,
           isRightAction:false,
           isDetail:false,
           activeIndex:'personalCenter'
        }
      },
      // 环保模块
      {
        path:"/app_dashboard",
        name: "app_dashboard",
        component: ()=>import("../views/Epp/dashboard/dashboard.vue"),
        meta:{
           type:"",
           title:"环保首页",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'index',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
      {
        path:"/alarm",
        name: "alarm",
        component: ()=>import("../views/Epp/Alarm/Alarm.vue"),
        meta:{
           title:"报警",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'index',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
      {
        path:"/online",
        name: "online",
        component: ()=>import("../views/Epp/Online/Online.vue"),
        meta:{
          type:"",
          title:"在线监测",
          isBack:true,
          isRightAction:false,
          isDetail:false,
          parent:'index',
          className:'IndexBox',
          activeIndex:'index'
        }
      },
      {
        path:"/foctordetail/:type/:title",
        name: "FoctorDetail",
        component: ()=>import("../views/Epp/dashboard/foctorDetail.vue"),
        meta:{
           type:"",
           title:"排口详情",
           isBack:true,
           isRightAction:false,
           isDetail:false,
           parent:'online',
           activeIndex:'index'
        }
      },
      {
        path:"/history/:parentType/:parentTitle/:queryId/:factortbale/:factortlist/:outlet_id/:tablenameOnlineManual",
        name: "History",
        component: ()=>import("../views/Epp/dashboard/history.vue"),
        meta:{
           type:"",
           title:"历史曲线",
           isBack:true,
           isRightAction:false,
           isDetail:false,
           parent:'online',
           activeIndex:'index'
        }
      },
      
      // 作业许可
      {
        path:"/siteManage",
        name: "siteManage",
        component: ()=>import("../views/Ptw/siteManage/siteManage.vue"),
        meta:{
          type:"",
          title:"现场管理",
          isBack:true,
          isRightAction:false,
          isDetail:false,
          parent:'index',
          className:'IndexBox',
          activeIndex:'index'
        },
      },
      // {
      //   path:"/actionsDetail/:id",
      //   name: "actionsDetail",
      //   component: ()=>import("../views/Ptw/actionsDetail/actionsDetail.vue"),
      //   meta:{
      //     type:"",
      //     title:"现场操作",
      //     isBack:true,
      //     isRightAction:false,
      //     isDetail:false,
      //     parent:'siteManage',
      //     className:'IndexBox',
      //     activeIndex:'index'
      //   },
      // },      
      {
        path:"/ptwDetail/:id",
        name: "ptwDetail",
        component: ()=>import("../views/Ptw/ptwDetail/ptwDetail.vue"),
        meta:{
          type:"",
          title:"作业票详情",
          isBack:true,
          isRightAction:false,
          isDetail:true,
          parent:'siteManage',
          className:'IndexBox',
          activeIndex:'index'
        },
      },
      {
        path:"/acceptance/:id",
        name: "acceptance",
        component: ()=>import("../views/Ptw/acceptance/acceptance.vue"),
        meta:{
          type:"",
          title:"验收任务",
          isBack:true,
          isRightAction:false,
          isDetail:true,
          parent:'ptwDetail',//'siteManage',
          className:'IndexBox',
          activeIndex:'index'
        },
      },
      {
        path:"/acceptanceCheck/:id/:end",
        name: "acceptanceCheck",
        component: ()=>import("../views/Ptw/acceptanceCheck/acceptanceCheck.vue"),
        meta:{
          type:"",
          title:"现场验收",
          isBack:true,
          isRightAction:false,
          isDetail:true,
          parent:'ptwDetail',//'siteManage',
          className:'IndexBox',
          activeIndex:'index'
        },
      },
      
      {
        path:"/detectionTask/:id/:typeId/:area",
        name: "detectionTask",
        component: ()=>import("../views/Ptw/detectionTask/detectionTask.vue"),
        meta:{
          type:"",
          title:"发布检测任务",
          isBack:true,
          isRightAction:false,
          isDetail:true,
          parent:'ptwDetail',//'siteManage',
          className:'IndexBox',
          activeIndex:'index'
        },
      },
      {
        path:"/delayTask/:id/:typeId",
        name: "delayTask",
        component: ()=>import("../views/Ptw/delayTask/delayTask.vue"),
        meta:{
          type:"",
          title:"延期审批",
          isBack:true,
          isRightAction:false,
          isDetail:true,
          parent:'ptwDetail',//'siteManage',
          className:'IndexBox',
          activeIndex:'index'
        },
      },
      {
        path:"/siteConfrim/:id/:typeId/:location/:status",
        name: "siteConfrim",
        component: ()=>import("../views/Ptw/siteConfrim/siteConfrim.vue"),
        meta:{
          type:"",
          title:"现场确认",
          isBack:true,
          isRightAction:false,
          isDetail:true,
          parent:'ptwDetail',
          className:'IndexBox',
          activeIndex:'index'
        },
      },
      {
        path:"/sign/:id/:typeId/:company_fire_watch/:ct_fire_watch/:supervisor_id/:worklevel/:status",
        name: "sign",
        component: ()=>import("../views/Ptw/sign/sign.vue"),
        meta:{
          type:"",
          title:"作业票签发",
          isBack:true,
          isRightAction:false,
          isDetail:true,
          parent:'ptwDetail',
          className:'IndexBox',
          activeIndex:'index'
        },
      },
      {
        path:"/sitePatrol/:id/:location",
        name: "sitePatrol",
        component: ()=>import("../views/Ptw/sitePatrol/sitePatrol.vue"),
        meta:{
          type:"",
          title:"现场巡检",
          isBack:true,
          isRightAction:false,
          isDetail:true,
          parent:'ptwDetail',
          className:'IndexBox',
          activeIndex:'index'
        },
      },
      {
        path:"/delay/:id/:start/:end",
        name: "delay",
        component: ()=>import("../views/Ptw/delay/delay.vue"),
        meta:{
          type:"",
          title:"延期申请",
          isBack:true,
          isRightAction:false,
          isDetail:true,
          parent:'ptwDetail',
          className:'IndexBox',
          activeIndex:'index'
        },
      },
      {
        path:"/closePtw/:id/:start/:end",
        name: "closePtw",
        component: ()=>import("../views/Ptw/closePtw/closePtw.vue"),
        meta:{
          type:"",
          title:"关票操作",
          isBack:true,
          isRightAction:false,
          isDetail:true,
          parent:'ptwDetail',
          className:'IndexBox',
          activeIndex:'index'
        },
      },
      
      {
        path:"/ptwlist",
        name: "ptwlist",
        component: ()=>import("../views/Ptw/ptwlist/ptwlist.vue"),
        meta:{
          type:"",
          title:"作业票",
          isBack:true,
          isRightAction:false,
          isDetail:true,
          parent:'index',
          className:'IndexBox',
          activeIndex:'index'
        },
      },
      // 隐患追踪
      {
        path:"/LurkingPerilPage",
        name: "LurkingPerilPage",
        component: ()=>import("../views/CT/LurkingPeril/LurkingPerilPage.vue"),
        meta:{
           type:"",
           title:"隐患追踪",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'index',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
      // 违规录入
      {
        path:"/ViolationPage",
        name: "ViolationPage",
        component: ()=>import("../views/CT/Violation/ViolationPage.vue"),
        meta:{
           type:"",
           title:"违规录入",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'index',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
      // 授权培训
      {
        path:"/AuthorizedTrainPage",
        name: "AuthorizedTrainPage",
        component: ()=>import("../views/CT/AuthorizedTrain/AuthorizedTrainPage.vue"),
        meta:{
           type:"",
           title:"授权培训",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'index',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
      // 安全培训
      {
        path:"/SafetyTrainPage",
        name: "SafetyTrainPage",
        component: ()=>import("../views/CT/SafetyTrain/SafetyTrainPage.vue"),
        meta:{
           type:"",
           title:"安全培训",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'index',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
      {
        path:"/fileDetail/:active/:id",
        name: "fileDetail",
        component: ()=>import("../views/CT/fileDetail/fileDetail.vue"),
        meta:{
           type:"",
           title:"资料查看",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'index',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
      // 承包商安全平台
      {
        path:"/TrainList",
        name: "TrainList",
        component: ()=>import("../views/CT/Train/Train.vue"),
        meta:{
           type:"",
           title:"培训考试",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'index',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
      {
        path:"/personInfo/:id",
        name: "personInfo",
        component: ()=>import("../views/CT/PersonnelInfo/PersonnelInfo.vue"),
        meta:{
           type:"",
           title:"人员信息",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'index',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
      {
        path:"/TrainPaper/:id",
        name: "TrainPaper",
        component: ()=>import("../views/CT/Train/TrainPaper.vue"),
        meta:{
           type:"",
           title:"发放试卷",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'TrainList',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
      {
        path:"/TrainResult/:id",
        name: "TrainResult",
        component: ()=>import("../views/CT/Train/TrainResult.vue"),
        meta:{
           type:"",
           title:"培训结果",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'TrainList',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
      {
        path:"/TrainAnswerDetail/:id",
        name: "TrainAnswerDetail",
        component: ()=>import("../views/CT/Train/TrainAnswerDetail.vue"),
        meta:{
           type:"",
           title:"答题详情",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'TrainList',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
      {
        path:"/AnswerDetails/:id/:userid/:is_qualified",
        name: "AnswerDetails",
        component: ()=>import("../views/CT/Train/AnswerDetails.vue"),
        meta:{
           type:"",
           title:"答题详情",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'TrainList',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
      // 每日风险承诺
      {
        path:"/TdsList",
        name: "TdsList",
        component: ()=>import("../views/TSD/TdsList/TdsList.vue"),
        meta:{
           type:"",
           title:"技术服务部",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'index',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
      {
        path:"/TdsDetail/:id?",
        name: "TdsDetail",
        component: ()=>import("../views/TSD/TdsList/TdsDetail.vue"),
        meta:{
           type:"",
           title:"技术服务部风险研判详情",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'TdsList',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
      {
        path:"/SafetyAndOH",
        name: "SafetyAndOH",
        component: ()=>import("../views/TSD/SafetyAndOH/SafetyAndOH.vue"),
        meta:{
           type:"",
           title:"安全和职业健康部",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'index',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
      {
        path:"/SafetyAndOHDetail/:id?",
        name: "SafetyAndOHDetail",
        component: ()=>import("../views/TSD/SafetyAndOH/SafetyAndOHDetail.vue"),
        meta:{
           type:"",
           title:"安全和职业健康部风险研判详情",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'SafetyAndOH',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
      {
        path:"/PIC",
        name: "PIC",
        component: ()=>import("../views/TSD/PIC/PIC.vue"),
        meta:{
           type:"",
           title:"主要负责人",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'index',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
      {
        path:"/PICDetail/:id?",
        name: "PICDetail",
        component: ()=>import("../views/TSD/PIC/PICDetail.vue"),
        meta:{
           type:"",
           title:"主要负责人风险研判详情",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'PIC',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
      {
        path:"/PD",
        name: "PD",
        component: ()=>import("../views/TSD/PD/PD.vue"),
        meta:{
           type:"",
           title:"生产部",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'index',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
      {
        path:"/PDDetail/:id?",
        name: "PDDetail",
        component: ()=>import("../views/TSD/PD/PDDetail.vue"),
        meta:{
           type:"",
           title:"生产部风险研判详情",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'PD',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
      {
        path:"/ESG",
        name: "ESG",
        component: ()=>import("../views/TSD/ESG/ESG.vue"),
        meta:{
           type:"",
           title:"环境和社会治理部",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'index',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
      {
        path:"/ESGDetail/:id?",
        name: "ESGDetail",
        component: ()=>import("../views/TSD/ESG/ESGDetail.vue"),
        meta:{
           type:"",
           title:"环境和社会治理部风险研判详情",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'ESG',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
      {
        path:"/QI",
        name: "QI",
        component: ()=>import("../views/TSD/QI/QI.vue"),
        meta:{
           type:"",
           title:"质量和创新部",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'index',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
      {
        path:"/QIDetail/:id?",
        name: "QIDetail",
        component: ()=>import("../views/TSD/QI/QIDetail.vue"),
        meta:{
           type:"",
           title:"质量和创新部风险研判详情",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'QI',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
      {
        path:"/ED",
        name: "ED",
        component: ()=>import("../views/TSD/ED/ED.vue"),
        meta:{
           type:"",
           title:"工程部",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'index',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
      {
        path:"/EDDetail/:id?",
        name: "EDDetail",
        component: ()=>import("../views/TSD/ED/EDDetail.vue"),
        meta:{
           type:"",
           title:"工程部风险研判详情",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'ED',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
      {
        path:"/RST_CONFIG",
        name: "RST_CONFIG",
        component: ()=>import("../views/TSD/RST_CONFIG/RST_CONFIG.vue"),
        meta:{
           type:"",
           title:"内容管理",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'index',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
      {
        path:"/RstConfigDetail/:id?",
        name: "RstConfigDetail",
        component: ()=>import("../views/TSD/RST_CONFIG/RstConfigDetail.vue"),
        meta:{
           type:"",
           title:"风险研判内容详情",
           isBack:true,
           isRightAction:false,
           isDetail:true,
           parent:'RST_CONFIG',
           className:'IndexBox',
           activeIndex:'index'
        }
      },
    ]

  },
  {
    path:"/Login",
    name:"Login",
    component: Login
  },

  {
    path:"/404/:msg",
    name:"404",
    component: ()=>import("../views/404.vue"),
    meta:{
       type:"",
       title:"调用微信接口报错",
       isBack:false,
       isRightAction:false,
       isDetail:false
    }
    
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  // history: createWebHistory(process.env.BASE_URL),  
  routes
});

export default router;
