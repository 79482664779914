<template>
  <div class="main_login">
    <div class="loginbox">
      <div class="login_logo">
        <div class="logo_img"></div>
        <div class="logo_title">管维EHSQ平台</div>
      </div>

      <el-form
        :model="loginForm"
        ref="loginSubmit"
        label-width="0"
        class="demo-ruleForm"
        style="width:240px;margin:0 auto;"
      >
        <el-form-item
          prop="loginName"
          :rules="[
            {
              required: true,
              message: '请输入登录名',
              trigger: ['blur', 'change']
            }
          ]"
        >
          <el-input v-model="loginForm.loginName"></el-input>
        </el-form-item>
        <el-form-item
          prop="password"
          :rules="[
            {
              required: true,
              message: '请输入密码',
              trigger: ['blur', 'change']
            }
          ]"
        >
          <el-input type="password" v-model="loginForm.password"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm()">立即登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import Http from "../api/http";
export default {
  setup() {
    const loginForm = reactive({
      method: "login",
      loginName: "",
      password: ""
    });
    const loginSubmit = ref(null);
    const router = useRouter();
    const submitForm = () => {
      loginSubmit.value.validate(valid => {
        if (valid) {
          Http.get(loginForm, false, true, false)
            .then(res => {
              localStorage.setItem('app_permission',JSON.stringify(res.map.permission));
              localStorage.setItem(
                "app_organization_id",
                res.map.ORGANIZATION_ID
              );
              localStorage.setItem("app_user_name", res.map.NAME);
              localStorage.setItem("app_user_id", res.map.ID);
              localStorage.setItem("ptw_gas_checker", res.map.ptw_gas_checker);
              localStorage.setItem("app_user_mobile", res.map.MOBILE);
              localStorage.setItem(
                "app_user_organization",
                res.map.ORGANIZATION_CODE
              );
              localStorage.setItem("app_user_email", res.map.EMAIL);
              localStorage.setItem(
                "app_user_department",
                res.map.DEPARTMENT_NAME
              );
              localStorage.setItem(
                "ct_contractor_info_id",
                res.map.ct_contractor_info_id || ""
              );
              if (window.JPush) {
                window.JPush.setAlias(
                  { sequence: 1, alias: res.map.ID.toString() },
                  function() {
                    //  result alert("alias设置成功："+result.alias);
                  },
                  function(error) {
                    alert("err:" + error.code);
                  }
                );
              }
              router.push("/");
            })
            .catch(() => {});
        } else {
          return false;
        }
      });
    };

    onMounted(() => {});
    return {
      loginForm,
      loginSubmit,
      submitForm
    };
  }
};
</script>
